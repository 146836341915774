import React from 'react';
import { Link } from 'react-router-dom';
import ellel1 from '../../assets/portfolios/elelle/elellecover.png';
import barwaqo1 from '../../assets/portfolios/barwaaqo/barwaqocover.png';
import sahid1 from '../../assets/portfolios/sahid/sahidcover.png';
import somali1 from '../../assets/portfolios/somali/somalicover.png';
import kaboor1 from '../../assets/portfolios/kaboor/kaporcover.png';
import shafi from '../../assets/portfolios/shafi/shaficover.png';

// import font
import '@fontsource/sora';
import '@fontsource/sora/400.css';

export default function Logos() {
  return (
    <section class="py-8 bg-gray-100 sm:py-10 lg:py-12">
      <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-8xl">
        <div class="justify-center max-w-7xl px-4 py-4 mx-auto lg:py-0">
          <h2
            style={{ fontFamily: 'Sora' }}
            className="py-6 px-3 text-3xl font-bold leading-tight text-gray-900 sm:text-5xl sm:leading-tight lg:leading-tight lg:text-4xl "
          >
            Portfolio
          </h2>
          <div class="grid grid-cols-1 gap-6 md:gap-6 lg:grid-cols-3 md:grid-cols-2">
            <Link to="/projects/1">
              <div class="w-[440px] p-3">
                <img class="h-72 w-full object-cover" src={ellel1} alt="img" />
                <ul class="mt-3 text-left">
                  <li class="mr-2">
                    <h2
                      style={{ fontFamily: 'Sora' }}
                      className="text-md font-semibold "
                    >
                      Elelle Petroleum
                    </h2>
                    <p className="text-sm text-gray-600">
                      Visual Identity Design
                    </p>
                  </li>
                </ul>
              </div>
            </Link>
            <Link to="/projects/2">
              <div class="w-[440px] p-3">
                <img class="h-72 w-full object-cover" src={sahid1} alt="img" />
                <ul class="mt-3 text-left">
                  <li class="mr-2">
                    <h2
                      style={{ fontFamily: 'Sora' }}
                      className="text-md font-semibold "
                    >
                      Sahid Business Group
                    </h2>
                    <p className="text-sm text-gray-600">Logo Design</p>
                  </li>
                </ul>
              </div>
            </Link>
            <Link to="/projects/3">
              <div class="w-[440px] p-3">
                <img
                  class="h-72 w-full object-cover"
                  src={barwaqo1}
                  alt="img"
                />
                <ul class="mt-3 text-left">
                  <li class="mr-2">
                    <h2
                      style={{ fontFamily: 'Sora' }}
                      className="text-md font-semibold "
                    >
                      Barwaqo
                    </h2>
                    <p className="text-sm text-gray-600">Logo Design</p>
                  </li>
                </ul>
              </div>
            </Link>

            <Link to="/projects/4">
              <div class="w-[440px] p-3">
                <img
                  class="h-72 w-full object-cover "
                  src={somali1}
                  alt="img"
                />
                <ul class="mt-3 text-left">
                  <li class="mr-2">
                    <h2
                      style={{ fontFamily: 'Sora' }}
                      className="text-md font-semibold "
                    >
                      Somali StoryTellers
                    </h2>
                    <p className="text-sm text-gray-600">Logo Design</p>
                  </li>
                </ul>
              </div>
            </Link>
            <Link to="/projects/5">
              <div class="w-[440px] p-3">
                <img
                  class="h-72 w-full object-cover "
                  src={kaboor1}
                  alt="img"
                />
                <ul class="mt-3 text-left">
                  <li class="mr-2">
                    <h2
                      style={{ fontFamily: 'Sora' }}
                      className="text-md font-semibold "
                    >
                      Kapor Eats
                    </h2>
                    <p className="text-sm text-gray-600">Logo Design</p>
                  </li>
                </ul>
              </div>
            </Link>
            <Link to="/projects/6">
              <div class="w-[440px] p-3">
                <img class="h-72 w-full object-cover " src={shafi} alt="img" />
                <ul class="mt-3 text-left">
                  <li class="mr-2">
                    <h2
                      style={{ fontFamily: 'Sora' }}
                      className="text-md font-semibold "
                    >
                      Shafi Fitness
                    </h2>
                    <p className="text-sm text-gray-600">Logo Design</p>
                  </li>
                </ul>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
