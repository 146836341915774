import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const ProjectDetails = () => {
  const { projectId } = useParams();
  const [projectDetails, setProjectDetails] = useState(null);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await fetch('/projects.json'); // Adjust the path accordingly
        const data = await response.json();

        const selectedProject = data.find(
          (project) => project.id === parseInt(projectId)
        );
        setProjectDetails(selectedProject);
      } catch (error) {
        console.error('Error fetching project details:', error);
      }
    };

    fetchProjectDetails();
  }, [projectId]);

  if (!projectDetails) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div class="flex min-h-screen items-center justify-left">
        <div class="px-40">
          <div class="grid grid-cols-12 ">
            <div class="col-span-12 lg:col-start-4 lg:col-span-6">
              <hr class="my-6 border-blue-gray-50" />
              <h5 class="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                {projectDetails.title}
              </h5>
              <div class="block antialiased w-[700px] font-sans text-sm mb-14 font-normal text-gray-600">
                {projectDetails.description}
              </div>
              <div class="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-gray-600">
                {projectDetails.images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`${projectDetails.title} - Image ${index + 1}`}
                    class="object-cover w-full h-full m-4"
                  />
                ))}
              </div>
              <div className="text-left ml-3 mt-4 mb-6">
                <a
                  href={projectDetails.link}
                  className="text-semibold text-xl cursor-pointer text-blue-800"
                >
                  To see the full project go to my behance
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectDetails;
