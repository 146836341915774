import React, { useState } from 'react';
import logo from '../assets/khalidlogo.png';

const Navbar = () => {
  const [open, setOpen] = useState(false);

  return (
    <section>
      <div className="max-w-7xl px-4 mx-auto " data-x-data="{open:false}">
        <nav className="flex items-center justify-between py-4 text-black">
          <a href="/" className="text-xl leading-none ">
            <img src={logo} alt="logo" className="w-full h-8" />
          </a>
          <div style={{ fontFamily: 'Inter' }}>
            <ul className="hidden lg:w-auto lg:space-x-12 lg:items-center lg:flex">
              <li>
                <a href="/" className="font-bold text-sm">
                  Home
                </a>
              </li>
              <li>
                <a href="/logos" className=" font-bold text-sm">
                  Logos
                </a>
              </li>

              <li>
                <a href="#contact" className="font-bold text-sm">
                  Contact
                </a>
              </li>
            </ul>
          </div>

          <div className="lg:hidden">
            <button
              className="flex items-center px-3 py-2 text-black border border-black rounded hover:bg-black hover:text-white lg:hidden"
              onClick={() => setOpen(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-list"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                />
              </svg>
            </button>
          </div>
          {/* ... (social media icons) */}
          <div class="hidden lg:block">
            <a
              href="https://docs.google.com/document/d/1_MnjOUney00vrobkGjAXmy7DwF4BiLZT-UbH_-tO9vs/edit?usp=drive_link"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-4 py-3 mr-2 text-xs font-semibold leading-none text-black border border-gray-800 rounded-full  hover:bg-black hover:text-white"
            >
              Download CV
            </a>
          </div>
        </nav>

        {/* Mobile Sidebar */}
        <div
          className={`fixed inset-0 w-full bg-black opacity-25  lg:hidden ${
            open
              ? 'translate-x-0 ease-in-opacity-100'
              : '-translate-x-full ease-out opacity-0'
          }`}
        ></div>

        <div
          className={`absolute inset-0 z-10 h-screen p-3 text-black duration-500 transform shadow-md  bg-black w-80 lg:hidden lg:transform-none lg:relative ${
            open
              ? 'translate-x-0 ease-in-opacity-100'
              : '-translate-x-full ease-out opacity-0'
          }`}
        >
          <div className="flex justify-between px-5 py-2">
            <a className="text-2xl font-bold text-white" href="/">
              <img src={logo} alt="logo" className="w-full h-6  rounded-lg" />
            </a>
            <button
              className="rounded-md hover:text-gray-400 lg:hidden text-white"
              onClick={() => setOpen(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-x-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </div>
          <div style={{ fontFamily: 'Inter' }}>
            <ul className="px-5 text-left mt-7">
              <li className="pb-3">
                <a href="/" className="text-sm text-white hover:text-gray-400 ">
                  Home
                </a>
              </li>
              <li className="pb-3">
                <a
                  href="/logos"
                  className="text-sm text-white hover:text-gray-400"
                >
                  Logos
                </a>
              </li>
              <li className="pb-3">
                <a
                  href="#contact"
                  className="text-sm text-white hover:text-gray-400"
                >
                  Contact
                </a>
              </li>

              {/* ... (other links in mobile sidebar) */}
            </ul>
          </div>
          <div className="border-t border-gray-100 my-7"></div>
          <div className="flex items-center px-5 lg:hidden">
            <div class="flex items-center mt-5 lg:hidden">
              <a
                href="https://docs.google.com/document/d/1_MnjOUney00vrobkGjAXmy7DwF4BiLZT-UbH_-tO9vs/edit?usp=drive_link"
                target="_blank"
                rel="noopener noreferrer"
                class="inline-block w-full px-4 py-3 mr-2 text-xs font-medium leading-none text-center text-black bg-white rounded-full hover:bg-gray-300"
              >
                Download CV
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Navbar;
