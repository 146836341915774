import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home/Homescreen';
import Logos from './pages/home/Logos';
// import About from './pages/about/About';
import BlogDetail from './pages/home/BlogDetail';
import ProjectDetails from './components/ProjectDetails';
import Navbar2 from './components/Navbar2';
import Footer2 from './components/Footer';

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <Navbar2 />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/logos" element={<Logos />} />
            <Route path="/blogdetail" element={<BlogDetail />} />
            <Route path="/projects/:projectId" element={<ProjectDetails />} />
            {/* <Route path="/about" element={<About />} /> */}
            <Route
              path="*"
              element={
                <div className="text-3xl text-green-900 justify-content-center h-dvh font-bold uppercase text-center">
                  404 Not Found
                </div>
              }
            />
          </Routes>
          <Footer2 />
        </div>
      </Router>
    </div>
  );
}

export default App;
