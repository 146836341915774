import React from 'react';
import packageImg from '../assets/collection4.png';

export default function Services() {
  return (
    <section class="text-gray-600  bg-white body-font overflow-hidden">
      <div class="container px-5 py-24 mx-auto sm:px-6 lg:px-8 max-w-8xl justify-center max-w-7xl">
        <div class="lg:w-4/5 mx-auto flex flex-wrap ">
          <div class="lg:w-1/2 w-full lg:pr-10 lg:py-14 mb-6 lg:mb-0">
            <h1 class="text-gray-900 text-3xl title-font font-body font-medium mb-4">
              Branding & Identity
            </h1>

            <p class="leading-relaxed mb-4 text-sm">
              Get a unique brand identity that resonates with your brand's vibe
              & connects to your audience.
            </p>
            <div class="flex border-t border-gray-200 py-2"></div>

            <div class="flex gap-x-3 mb-4">
              <button class="text-black bg-gray-100  px-4 py-2 rounded-full text-xs border-[1.5px] border-gray-300">
                Logo design
              </button>
              <button class="text-black bg-gray-100  px-4 py-2 rounded-full text-xs border-[1.5px] border-gray-300">
                Visual identity
              </button>
              <button class="text-black bg-gray-100  px-4 py-2 rounded-full text-xs border-[1.5px] border-gray-300">
                Brand guidelines
              </button>
            </div>
            <div class="flex gap-x-3 mb-4">
              <button class="text-black bg-gray-100  px-4 py-2 rounded-full text-xs border-[1.5px] border-gray-300">
                Brand collateral
              </button>
              <button class="text-black bg-gray-100  px-4 py-2 rounded-full text-xs border-[1.5px] border-gray-300">
                Social media
              </button>
              <button class="text-black bg-gray-100  px-4 py-2 rounded-full text-xs border-[1.5px] border-gray-300">
                Typography
              </button>
            </div>
            <div class="flex gap-x-3 mb-4">
              <button class="text-black bg-gray-100  px-4 py-2 rounded-full text-xs border-[1.5px] border-gray-300">
                Colors
              </button>
              <button class="text-black bg-gray-100  px-4 py-2 rounded-full text-xs border-[1.5px] border-gray-300">
                App icon
              </button>
              <button class="text-black bg-gray-100  px-4 py-2 rounded-full text-xs border-[1.5px] border-gray-300">
                Pitch decks
              </button>
            </div>
          </div>
          <img
            alt="collect"
            className="lg:w-1/2 w-full h-[400px]  object-contain rounded bg-white"
            src={packageImg}
          />
        </div>
      </div>
    </section>
  );
}
