import React from 'react';
import logo from '../assets/logowhite.png';

export default function Footer() {
  return (
    <footer class="text-white body-font bg-black ">
      <hr />
      <div class="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
        <a
          href="/"
          class="flex title-font font-medium items-center md:justify-start justify-center"
        >
          <img src={logo} className="w-full h-12 p-2" alt="logo" />
        </a>
        <p class="text-sm text-white sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">
          &copy; {new Date().getFullYear()} All Right Reserved —
          <a
            href="https://shaab.vercel.app/"
            class="text-white ml-1"
            rel="noopener noreferrer"
            target="_blank"
          >
            Developed: Shaab
          </a>
        </p>
      </div>
    </footer>
  );
}
