import React from 'react';
import khalidimg from '../../assets/1.png';

import '@fontsource/raleway';
import '@fontsource/raleway/400.css';
import '@fontsource/raleway/400-italic.css';

import '@fontsource/sora';
import '@fontsource/sora/400.css';

export default function Hero() {
  return (
    <div class="bg-white">
      <section class="pt-6 pb-8 sm:pb-0 lg:pt-0">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="grid max-w-lg grid-cols-1 mx-auto lg:max-w-full lg:items-center lg:grid-cols-2 gap-y-12 lg:gap-x-16">
            <div>
              <div class="text-center lg:text-left">
                <h1 class="text-3xl font-bold leading-tight text-gray-900 sm:text-5xl sm:leading-tight lg:leading-tight lg:text-5xl">
                  <span
                    className="font-normal text-lg"
                    style={{ fontFamily: 'Raleway' }}
                  >
                    {' '}
                    Hello, I’am
                  </span>{' '}
                  <br />
                  <span style={{ fontFamily: 'Sora' }} className="">
                    {' '}
                    Khaled Egal
                  </span>
                </h1>
                <p
                  style={{ fontFamily: 'Raleway' }}
                  class="mt-2 text-lg text-black sm:mt-8 font-raleway text-left"
                >
                  I’m a Brand identity Designer, I design standout logos and
                  visual identities. Whether you're an SME, corporate entity, or
                  a personal brand, I specialize in making you truly memorable.
                  Let's cook up something extraordinary for your brand!
                </p>
              </div>

              <div class="flex items-center justify-center mt-10 space-x-6 lg:justify-start sm:space-x-8">
                <div class="flex items-center">
                  <button
                    type="submit"
                    class="inline-flex px-6 py-3 text-md font-normal text-white transition-all duration-200 bg-gray-900 rounded-sm focus:outline-none focus:bg-gray-600 hover:bg-gray-200 hover:text-black"
                  >
                    View Projects
                  </button>
                </div>
              </div>
            </div>

            <div>
              <img class="w-full" src={khalidimg} alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
