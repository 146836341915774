import Logos from '../Logos';
import Hero from '../Hero';
import Services from '../../../components/Services';
import GetinTouch from '../../../components/GetinTouch';

export default function Home() {
  return (
    <>
      <Hero />
      <Logos />
      <Services />
      <GetinTouch />
    </>
  );
}
